/* src/components/LanguageSwitcher.css */
.language-switcher {
    position: fixed; /* Posición fija para que esté siempre visible */
    top: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.5); /* Fondo transparente */
    padding: 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .flag-icon {
    width: 24px; /* Ajusta el tamaño de los iconos según sea necesario */
    height: 24px;
    cursor: pointer;
    margin-left: 10px; /* Espacio entre los iconos */
  }
  