/* Importar las fuentes desde Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&family=Playfair+Display:wght@400;700&family=Roboto:wght@400;500&display=swap');

/* Estilos generales */
body {
  font-family: 'Roboto', sans-serif;
  background-color: #f4f7f6; /* Color de fondo */
  color: #2c3e50; /* Color de texto principal */
  margin: 0;
  padding: 0;
}

/* Estilos para encabezados */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif;
  color: #3498db;
  margin-bottom: 20px;
}

/* Estilos para el contenedor principal */
.home-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

/* Sección de subida de archivos */
.file-upload-section {
  margin-bottom: 20px;
}

/* Botón de selección de archivo */
.file-upload-button {
  display: inline-block;
  padding: 17px 35px;
  background-color: #3498db;
  color: #fff;
  border-radius: 5px;
  font-family: 'Montserrat', sans-serif;
  font-size: 22px;
  cursor: pointer;
  transition: background-color 0.3s;
  border: none;
}

.file-upload-button:hover {
  background-color: #2980b9;
}

.file-preview {
  margin-top: 10px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #2c3e50;
}

/* Botones principales */
.upload-button {
  font-family: 'Montserrat', sans-serif;
  background-color: #e67e22;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.upload-button:hover {
  background-color: #d35400;
}

.login-link {
  color: #3498db;
  font-family: 'Montserrat', sans-serif;
  text-decoration: none;
  display: block;
  margin-top: 20px;
}

.login-link:hover {
  text-decoration: underline;
}

.logo {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

@media (min-width: 1024px) {
  .logo {
    width: 200px; /* Tamaño más grande para pantallas de PC */
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .logo {
    width: 250px; /* Tamaño para tablets y pantallas medianas */
  }
}

@media (max-width: 767px) {
  .logo {
    width: 200px; /* Tamaño para teléfonos móviles */
  }
}
