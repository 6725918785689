/* Login.css */

/* Estilos para el contenedor principal */
.container {
    max-width: 200px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color: #f5f5f5;
    text-align: center;
  }
  
  /* Estilos para el encabezado */
  h2 {
    color: #3498db;
    font-size: 28px;
    margin-bottom: 20px;
    text-align: center;
  }

  p {
    
    font-size: 28px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  /* Estilos para etiquetas de formulario */
  label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    font-size: 16px;
    color: #333;
    text-align: left;
  }
  
  /* Estilos para campos de entrada */
  input {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    margin-bottom: 16px;
    transition: border-color 0.3s ease-in-out;
  }
  
  input:focus {
    border-color: #34db34;
  }
  
  /* Estilos para el botón de inicio de sesión */
  button {
    display: inline-block;
    padding: 12px 24px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #3498db;
    color: white;
    font-size: 18px;
    transition: background-color 0.3s ease-in-out;
    text-decoration: none;
  }
  
  button:hover {
    background-color: #2772a4;
  }
  
  /* Estilos para el enlace de registro */
  p {
    font-size: 16px;
    margin-top: 20px;
  }
  
  a {
    color: #3498db;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
  }
  
  a:hover {
    color: #2772a4;
  }
  
/* Salida.css (Adaptación para Salida.jsx) */
.salida-container {
  /* Estilos específicos para el contenedor de salida */
  background-color: #e6f7ff; /* Color de fondo diferente */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.salida-container h1 {
  /* Estilos para el título en Salida.jsx */
  color: #2772a4; /* Color de texto diferente */
  font-size: 24px;
  margin-bottom: 10px;
}

.salida-container p {
  /* Estilos para el párrafo en Salida.jsx */
  color: #333;
  font-size: 16px;
  margin-bottom: 20px;
}

.salida-container button {
  /* Estilos para el botón en Salida.jsx */
  background-color: #2772a4;
  color: #fff;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.salida-container button:hover {
  /* Efecto hover para el botón en Salida.jsx */
  background-color: #3498db;
}

p1 {
  color: #2772a4;
  font-size: 18px;
  margin-bottom: 20px;
  text-align: center;
  
}


  