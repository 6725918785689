/* Estilos para el contenedor de la página de descarga */
.download-container {
  text-align: center;
  padding: 20px;
}

h1 {
  margin-bottom: 20px;
}

/* Estilo del botón de descarga */
.download-button {
  display: block;
  margin: 0 auto 20px auto;
  padding: 10px 20px;
  font-size: 16px;
  color: #ffffff;
  background-color: #3498db;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.download-button:hover {
  background-color: #2980b9;
}

/* Estilo del botón "Volver al inicio" */
.back-button {
  display: block;
  margin: 0 auto 20px auto;
  padding: 10px 20px;
  font-size: 16px;
  color: #ffffff;
  background-color: #e67e22;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.back-button:hover {
  background-color: #d35400;
}

/* Estilos para el logotipo */
.logo2 {
  max-width: 100%;
  height: auto;
  margin-top: 20px;
}

/* Media queries para responsividad */
@media (min-width: 1024px) {
  .logo2 {
    width: 150px; /* Tamaño más pequeño para pantallas grandes */
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .logo2 {
    width: 120px; /* Tamaño para pantallas medianas */
  }
}

@media (max-width: 767px) {
  .logo2 {
    width: 100px; /* Tamaño más pequeño para pantallas pequeñas */
  }
}

@media (max-width: 768px) {
  .download-button,
  .back-button {
    width: 80%; /* Ajustar el ancho de los botones en pantallas pequeñas */
  }
}
