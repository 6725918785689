* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f4;
  color: #333;
}

.container {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2 {
  color: #3498db;
  margin-bottom: 20px;
  font-size: 24px;
  text-align: center;
}

.error {
  color: red;
  margin-bottom: 20px;
  text-align: center;
}

.history-container {
  margin-bottom: 20px;
}

.history-list {
  max-height: 200px;
  overflow-y: auto;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fafafa;
}

.history-list li {
  margin-bottom: 10px;
}

.big-upload-button {
  display: block;
  width: 100%;
  padding: 12px;
  margin-top: 20px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.big-upload-button:hover {
  background-color: #2980b9;
}

.file-preview {
  text-align: center;
  margin-top: 10px;
  font-size: 18px;
  color: #2c3e50;
}

@media (max-width: 768px) {
  .container {
    width: 90%;
  }
}

.logout-button {
  background-color: #ff4d4d;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
}

.logout-button:hover {
  background-color: #ff3333;
}